export default [
  {
    type: "ANAMNESTIC FORM",
    name: "ANAMNESTIC FORM",
    codiceDataCompletamento: "DATE_00",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      },
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATE_00",
        label: "Date ",
        type: "date"
      },
      {
        key: "ROLE",
        label: "Professional role in the structure",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Psychiatrist"
          },
          {
            value: 2,
            label: "Nurse"
          },
          {
            value: 3,
            label: "Psychologist"
          },
          {
            value: 4,
            label: "Social worker"
          },
          {
            value: 5,
            label: "Any other mental health worker"
          }
        ]
      },
      {
        key: "ROLE_a",
        label: "Specify the role ",
        if: null,
        type: "free-text"
      },
      {
        key: "Tipo_soggetto",
        label: "Type of subject",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Patient"
          },
          {
            value: 2,
            label: "Control"
          }
        ]
      },
      {
        key: "PATIENT_FORM_Age",
        label: "Age ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "18-24"
          },
          {
            value: 2,
            label: "25-34"
          },
          {
            value: 3,
            label: "35-44"
          },
          {
            value: 4,
            label: "45-54"
          },
          {
            value: 5,
            label: "55-65"
          }
        ]
      },
      {
        key: "PATIENT_FORM_Sex",
        label: "Sex",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Male"
          },
          {
            value: 2,
            label: "Female"
          }
        ]
      },
      {
        key: "PATIENT_FORM_Education",
        label: "Education",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Elementary School"
          },
          {
            value: 2,
            label: "Middle School"
          },
          {
            value: 3,
            label: "High School"
          },
          {
            value: 4,
            label: "University  Degree"
          },
          {
            value: 5,
            label: "Post Graduate Training"
          }
        ]
      },
      {
        key: "PATIENT_FORM_maritalstatus",
        label: "Marital Status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Celibate/Maiden, not cohabiting"
          },
          {
            value: 2,
            label: "Married"
          },
          {
            value: 3,
            label: "Celibate/Maiden, cohabiting"
          },
          {
            value: 4,
            label: "Divorced"
          },
          {
            value: 5,
            label: "Widower/Widow"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_physicalact",
        label: "Do you regularly pratice physical activity?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_physicalactspec",
        label: "Specify the physical activity",
        if: [
          {
            key: "SCHEDA_SOGGETTO_physicalact",
            comparator: "=",
            value: 1
          }
        ],
        type: "free-text"
      },
      {
        key: "SCHEDA_SOGGETTO_physicalacttime",
        label: "How many times during the week?",
        if: [
          {
            key: "SCHEDA_SOGGETTO_physicalact",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "1  a week"
          },
          {
            value: 2,
            label: "2 a week"
          },
          {
            value: 3,
            label: "3 or more a week"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_occupation",
        label: "Current working status",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Full time job"
          },
          {
            value: 2,
            label: "Part time job"
          },
          {
            value: 3,
            label: "Training"
          },
          {
            value: 4,
            label: "Student"
          },
          {
            value: 5,
            label: "Housewife"
          },
          {
            value: 6,
            label: "Unemployed"
          },
          {
            value: 7,
            label: "Retired"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_income",
        label: "Source of income",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Employee"
          },
          {
            value: 2,
            label: "Self-employment"
          },
          {
            value: 3,
            label: "Pension"
          },
          {
            value: 4,
            label: "Other"
          },
          {
            value: 5,
            label: "None"
          }
        ]
      },
      {
        key: "SCHEDA_SOGGETTO_fam",
        label: "Who do you live with?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Alone"
          },
          {
            value: 2,
            label: "Spouse with or without children"
          },
          {
            value: 3,
            label: "Other family memebrs"
          },
          {
            value: 4,
            label: "Friends"
          }
        ]
      }
    ]
  },
  {
    type: "PHYSICAL STATUS",
    name: "PHYSICAL FORM",
    codiceDataCompletamento: "DATE_01",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_01",
        label: "Date ",
        type: "date"
      },
      {
        key: "PHYSICAL_FORM_waist",
        label: "Waist circumference",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_Weight",
        label: "Current Weight (Kg)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "number",
        range: {
          from: 30,
          to: 150
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_Height",
        label: "Current Height (centimeters)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "number",
        range: {
          from: 120,
          to: 200
        },
        step: "any",
        auto: null
      },
      {
        key: "PHYSICAL_FORM_BMI",
        label: "BMI",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: {
          specialType: "BMI",
          massa: "PHYSICAL_FORM_Weight",
          altezza: "PHYSICAL_FORM_Height",
          factor: 10000
        }
      },
      {
        key: "PHYSICAL_FORM_triglycerides",
        label: "Elevated triglycerides",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lower than 150mg/dL"
          },
          {
            value: 2,
            label: "Higher than 150mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_HDL",
        label: "Reduced HDL-Cholesterol",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lower than 40 mg/dL in males"
          },
          {
            value: 2,
            label: "Lower than 50 mg/dL in females"
          },
          {
            value: 3,
            label: "Higher than 40 mg/dL in males"
          },
          {
            value: 4,
            label: "Higher than 50 mg/dL in females"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_pressure",
        label: "Blood Pressure",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Lower than 130 systolic mmHg"
          },
          {
            value: 2,
            label: "Higher than 130 systolic mmHg"
          },
          {
            value: 3,
            label: "Lower than 85 diastolic mmHg"
          },
          {
            value: 4,
            label: "Higher than 85 diastolic mmHg"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_glucose",
        label: "Fasting Glucose",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Lower than 100mg/dL"
          },
          {
            value: 2,
            label: "Higher than 100mg/dL"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_medcom",
        label: "Medical comorbidities",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Pazienti"
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Diabetes Mellitus"
          },
          {
            value: 2,
            label: "Cardiovascular Disease"
          },
          {
            value: 3,
            label: "Obesity"
          },
          {
            value: 4,
            label: "Renal Disoders"
          },
          {
            value: 5,
            label: "Neurological Condition"
          },
          {
            value: 6,
            label: "Migraine"
          },
          {
            value: 7,
            label: "Musculoskeletal Disoder"
          },
          {
            value: 8,
            label: "Ostheoporis"
          },
          {
            value: 9,
            label: "Endocrine Pathologies"
          },
          {
            value: 10,
            label: "Other"
          }
        ]
      },
      {
        key: "PHYSICAL_FORM_medcomspec",
        label: "Specify other comorbidities",
        if: [
          {
            key: "PHYSICAL_FORM_medcom",
            comparator: "=",
            value: 10,
            isMulti: true
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "BIOMARKERS",
    name: "PHYSICAL FORM",
    codiceDataCompletamento: "DATE_23",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_23",
        label: "Date ",
        type: "date"
      },
      {
        key: "BIOMARKERS_0",
        label: "Please state the unit of measurement if it is different",
        if: null,
        type: "description"
      },
      {
        key: "BIOMARKERS_1",
        label: "Adiponectin/leptin ratio ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_2",
        label: "Alanine transaminase (ALT) U/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_3",
        label: "Aspartate Transaminase (AST) U/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_4",
        label: "Hemoglobin g/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_5",
        label: "White blood cell count 10^9/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_6",
        label: "Platelet count  10^9/L ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_7",
        label: "C Peptide ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_8",
        label: "Fasting plasma glucose mg/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_9",
        label: "Gamma-Glutamyl Transferase (GGT) U/L ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_10",
        label: "High-density lipoprotein (HDL)-cholesterol mg/dl ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_11",
        label: "hsCRP mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_12",
        label: "Oxidized LDL ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_13",
        label: "Triglycerides mg/dL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_14",
        label: "Albumin g/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_15",
        label: "Cystatine-C mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_16",
        label: "IL-6 pg/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_17",
        label: "LDL-cholesterol mg/dL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_18",
        label: "Salivary cortisol ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_19",
        label: "Serum amyloid A mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_20",
        label: "Testosterone ng/mL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_21",
        label: "Thyroid antibodies (TPOAb) U.I./mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_22",
        label: "Thyroid antibodies (TGAAb) U.I./mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_23",
        label: "TNF-α pg/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_24",
        label: "Total Cholesterol mg/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_25",
        label: "Thyroid-stimulating hormone (TSH) micUl/ml ",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "CONTROL STATUS",
    name: "Control FORM",
    codiceDataCompletamento: "DATE_Control_FORM",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Controllo"
      }
    ],
    domande: [
      {
        key: "DATE_Control_FORM",
        label: "Date ",
        type: "date"
      },
      {
        key: "Control_FORM_Weight",
        label: "Current Weight (Kg)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "Control_FORM_Height",
        label: "Current Height (centimeters)",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "Control_FORM_BMI",
        label: "BMI",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: {
          specialType: "BMI",
          massa: "Control_FORM_Weight",
          altezza: "Control_FORM_Height",
          factor: 10000
        }
      },
      {
        key: "Control_FORM_triglycerides",
        label: "Are you in treatment for elevated triglycerides?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "Control_FORM_HDL",
        label: "Are you in treatment for high cholesterol?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "Control_FORM_pressure",
        label: "Are you in treatment for elevated blood pressure?",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "Control_FORM_glucose",
        label: "Are you in treatment for diabetes? Or insulin resistance? ",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "Control_FORM_medcom",
        label: "Medical comorbidities",
        if: [
          {
            key: "USER_INFO_userType",
            comparator: "=",
            value: "Controllo"
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Diabetes Mellitus"
          },
          {
            value: 2,
            label: "Cardiovascular Disease"
          },
          {
            value: 3,
            label: "Obesity"
          },
          {
            value: 4,
            label: "Renal Disoders"
          },
          {
            value: 5,
            label: "Neurological Condition"
          },
          {
            value: 6,
            label: "Migraine"
          },
          {
            value: 7,
            label: "Musculoskeletal Disoder"
          },
          {
            value: 8,
            label: "Ostheoporis"
          },
          {
            value: 9,
            label: "Endocrine Pathologies"
          },
          {
            value: 10,
            label: "Other"
          }
        ]
      },
      {
        key: "Control_FORM_medcomspec",
        label: "Specify the comorbidities",
        if: null,
        type: "free-text"
      },
      {
        key: "Control_FORM_medcomspec2",
        label: "Specify other comorbidities",
        if: [
          {
            key: "Control_FORM_medcom",
            comparator: "=",
            value: 10,
            isMulti: true
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "PSYCHIATRIC STATUS",
    name: "PSYCHIATRIC FORM",
    codiceDataCompletamento: "DATE_03",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_03",
        label: "Date",
        type: "date"
      },
      {
        key: "PSICH_diagnosiDB",
        label: "Diagnosis according to SCID",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Bipolar Disoder I"
          },
          {
            value: 2,
            label: "Bipolar Disoder II"
          },
          {
            value: 3,
            label: "NOS- Bipolar Disoder"
          },
          {
            value: 4,
            label: "Cyclothymic"
          }
        ]
      },
      {
        key: "PSICH_Duration",
        label: "Duration of illness (years)",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 55
        },
        step: "any",
        auto: null
      },
      {
        key: "PSICH_ageonset",
        label: "Age of onset",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Before 21 years"
          },
          {
            value: 2,
            label: "After 21 years"
          }
        ]
      },
      {
        key: "PSICH_season",
        label: "Does your bipolar disorder get worse in a certain season?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PSICH_season1",
        label: "In which season",
        if: [
          {
            key: "PSICH_season",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Winter"
          },
          {
            value: 2,
            label: "Summer"
          },
          {
            value: 3,
            label: "Spring"
          },
          {
            value: 4,
            label: "Autumn"
          }
        ]
      },
      {
        key: "PSICH_hosp",
        label: "Have you ever been hospitalized due to BD?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "2 months-1 year"
          },
          {
            value: 4,
            label: "1-2 years"
          },
          {
            value: 5,
            label: "more than 5 years"
          }
        ]
      },
      {
        key: "PSICH_hospage",
        label: "Age at first hospitalization",
        if: [
          {
            key: "PSICH_hosp",
            comparator: "=",
            value: 1
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_duration",
        label:
          "Approximate total duration of all psychiatric admissions in life (in psychiatric hospitals, SPDCs, psychiatric nursing homes, residential facilities) (add up the duration of admissions to the various psychiatric facilities)",
        if: [
          {
            key: "PSICH_hosp",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Less than 1 month"
          },
          {
            value: 2,
            label: "1-2 month"
          },
          {
            value: 3,
            label: "3-4 months"
          },
          {
            value: 4,
            label: "5-6 months"
          },
          {
            value: 5,
            label: "More"
          }
        ]
      },
      {
        key: "PSICH_nadmissions",
        label:
          "Total number of admissions to the HOSPITAL in the last year since the day of the survey ",
        if: [
          {
            key: "PSICH_hosp",
            comparator: "=",
            value: 1
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_diagncomorb",
        label:
          "Does the patient have a psychiatric diagnosis in comorbidity with DB?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "PSICH_6_diagnosiprincipale_comorb_quale",
        label: "Specify principal diagnosis in comorbidity",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Alcohol abuse/addiction"
          },
          {
            value: 2,
            label: "Drugs abuse/addiction"
          },
          {
            value: 3,
            label: "Anxiety disorders"
          },
          {
            value: 4,
            label: "Eating disorders"
          },
          {
            value: 5,
            label: "Personality disorders (DSM-5)"
          },
          {
            value: 6,
            label: "Obsessive-compulsive disorder"
          },
          {
            value: 7,
            label: "Somatoform disorders"
          },
          {
            value: 8,
            label: "Psychotic Disorder"
          },
          {
            value: 9,
            label: "Other"
          }
        ]
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale",
        label: " Other diagnosis",
        if: [
          {
            key: "PSICH_diagncomorb",
            comparator: "=",
            value: 1
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Alcohol abuse/addiction"
          },
          {
            value: 2,
            label: "Drugs abuse/addiction"
          },
          {
            value: 3,
            label: "Anxiety disorders"
          },
          {
            value: 4,
            label: "Eating disorders"
          },
          {
            value: 5,
            label: "Personality disorders (DSM-5)"
          },
          {
            value: 6,
            label: "Obsessive-compulsive disorder"
          },
          {
            value: 7,
            label: "Somatoform disorders"
          },
          {
            value: 8,
            label: "Psychotic Disorder"
          },
          {
            value: 9,
            label: "Other"
          }
        ]
      },
      {
        key: "PSICH_6__diagnosi_comorb_quale2",
        label: 'Specify "Other"',
        if: [
          {
            key: "PSICH_6__diagnosi_comorb_quale",
            comparator: "=",
            value: 9,
            isMulti: true
          }
        ],
        type: "free-text"
      },
      {
        key: "PSICH_trattpsico",
        label: "Psychopharmacological treatment on the day of detection ",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Antipsychotics 1st generation"
          },
          {
            value: 2,
            label: "Antipsychotics 2nd generation"
          },
          {
            value: 3,
            label: "Mood stabilisers (any, including lithium)"
          },
          {
            value: 4,
            label: "Antidepressants (any, 1st and 2nd generation)"
          },
          {
            value: 5,
            label:
              "Benzodiazepines (any, even if used for hypnoinductive purposes)"
          }
        ]
      },
      {
        key: "PSICH_6_anxietydisoders",
        label: "Specify the anxiety disoders",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 3,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Panic disorders without agoraphobia"
          },
          {
            value: 2,
            label: "Panic disorder with agoraphobia"
          },
          {
            value: 3,
            label: "Agoraphobia without panic disorder"
          },
          {
            value: 4,
            label: "Social phobia"
          },
          {
            value: 5,
            label: "Specif phobia"
          },
          {
            value: 6,
            label: "Substance-Induced Anxiety Disoder"
          },
          {
            value: 7,
            label: "Generalized Anxiety"
          },
          {
            value: 8,
            label: "Anxiety Disorder due to a general medical condition"
          }
        ]
      },
      {
        key: "PSICH_6_anxietydisoders_AOO",
        label: "Age of onset",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 3,
            isMulti: true
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_6_Obsessive-compulsivedisorder",
        label: "Age of onset",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 6,
            isMulti: true
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_6_PsychoticDisorder",
        label: "Specify the Psychotic disorder",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 8,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Schizophrenia"
          },
          {
            value: 2,
            label: "Schizoaffective disorder"
          },
          {
            value: 3,
            label: "Delusional disorder"
          },
          {
            value: 4,
            label: "Brief psychotic disorder"
          },
          {
            value: 5,
            label: "Substance-induced psychotic disorder"
          }
        ]
      },
      {
        key: "PSICH_6_PsychoticDisorder_AOO",
        label: "Age of onset",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 8,
            isMulti: true
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_6_EatingDisorder",
        label: "Specify the Eating Disorder",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 4,
            isMulti: true
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Anorexia Nervosa"
          },
          {
            value: 2,
            label: "Bulimia Nervosa"
          },
          {
            value: 3,
            label: "Binge Eating disoder"
          },
          {
            value: 4,
            label: "OSFED-eating disorder"
          }
        ]
      },
      {
        key: "PSICH_6_EatingDisorder_AOO",
        label: "Age of onset",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 4,
            isMulti: true
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "PSICH_6_Somatoformdisoder_AOO",
        label: "Age of onset",
        if: [
          {
            key: "PSICH_6_diagnosiprincipale_comorb_quale",
            comparator: "=",
            value: 7,
            isMulti: true
          }
        ],
        type: "number",
        range: null,
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "TREATMENT HISTORY",
    name: "TREATMENT HISTORY",
    codiceDataCompletamento: "DATE_TH",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_TH",
        label: "Date ",
        type: "date"
      },
      {
        key: "TH_1",
        label: "Have you ever used Lithium?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_1a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_1",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_1",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_1b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_1",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_1",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_1c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_1",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_1",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_1d",
        label: "Highest dose",
        if: [
          {
            key: "TH_1",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_1",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_2",
        label: "Have you ever used Valproate?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_2a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_2",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_2",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_2b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_2",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_2",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_2c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_2",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_2",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_2d",
        label: "Highest dose",
        if: [
          {
            key: "TH_2",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_2",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_3",
        label: "Have you ever used Carbamazepine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          },
          {
            value: 4,
            label: "Other"
          },
          {
            value: 5,
            label: "None"
          }
        ]
      },
      {
        key: "TH_3a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_3",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_3",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_3b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_3",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_3",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_3c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_3",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_3",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_3d",
        label: "Highest dose",
        if: [
          {
            key: "TH_3",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_3",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_4",
        label: "Have you ever used Excarbazepine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_4a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_4",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_4",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_4b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_4",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_4",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_4c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_4",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_4",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_4d",
        label: "Highest dose",
        if: [
          {
            key: "TH_4",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_4",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_5",
        label: "Have you ever used Lamotrigine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_5a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_5",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_5b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_5",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_5c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_5",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_5d",
        label: "Highest dose",
        if: [
          {
            key: "TH_5",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_5",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_6",
        label: "Have you ever used Citaprolam?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_6a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_6",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_6",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_6b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_6",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_6",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_6c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_6",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_6",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_6d",
        label: "Highest dose",
        if: [
          {
            key: "TH_6",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_6",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_7",
        label: "Have you ever used Clomipramine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_7a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_7",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_7",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_7b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_7",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_7",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_7c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_7",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_7",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_7d",
        label: "Highest dose",
        if: [
          {
            key: "TH_7",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_7",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_8",
        label: "Have you ever used Duloxetine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_8a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_8",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_8",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_8b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_8",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_8",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_8c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_8",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_8",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_8d",
        label: "Highest dose",
        if: [
          {
            key: "TH_8",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_8",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_9",
        label: "Have you ever used Escitalopram?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_9a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_9",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_9",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_9b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_9",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_9",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_9c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_9",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_9",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_9d",
        label: "Highest dose",
        if: [
          {
            key: "TH_9",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_9",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_10",
        label: "Have you ever used Fluoxetine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_10a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_10",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_10",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_10b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_10",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_10",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_10c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_10",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_10",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_10d",
        label: "Highest dose",
        if: [
          {
            key: "TH_10",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_10",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_11",
        label: "Have you ever used Mirtazapine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_11a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_11",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_11",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_11b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_11",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_11",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_11c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_11",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_11",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_11d",
        label: "Highest dose",
        if: [
          {
            key: "TH_11",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_11",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_12",
        label: "Have you ever used Paroxetine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_12a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_12",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_12",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_12b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_12",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_12",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_12c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_12",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_12",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_12d",
        label: "Highest dose",
        if: [
          {
            key: "TH_12",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_12",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_13",
        label: "Have you ever used Sertraline?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_13a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_13",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_13b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_13",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_13c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_13",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_13d",
        label: "Highest dose",
        if: [
          {
            key: "TH_13",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_13",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_14",
        label: "Have you ever used Venlafaxine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_14a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_14",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_14",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_14b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_14",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_14",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_14c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_14",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_14",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_14d",
        label: "Highest dose",
        if: [
          {
            key: "TH_14",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_14",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_15",
        label: "Have you ever used Trazodone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_15a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_15",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_15",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_15b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_15",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_15",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_15c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_15",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_15",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_15d",
        label: "Highest dose",
        if: [
          {
            key: "TH_15",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_15",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_16",
        label: "Have you ever used Alprazolam?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_16a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_16",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_16",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_16b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_16",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_16",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_16c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_16",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_16",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_16d",
        label: "Highest dose",
        if: [
          {
            key: "TH_16",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_16",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_17",
        label: "Have you ever used Buspirone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_17a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_17",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_17b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_17",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_17c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_17",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_17d",
        label: "Highest dose",
        if: [
          {
            key: "TH_17",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_17",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_18",
        label: "Have you ever used Clonazepam?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_18a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_18",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_18",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_18b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_18",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_18",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_18c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_18",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_18",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_18d",
        label: "Highest dose",
        if: [
          {
            key: "TH_18",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_18",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_19",
        label: "Have you ever used Diazepam?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_19a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_19",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_19",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_19b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_19",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_19",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_19c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_19",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_19",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_19d",
        label: "Highest dose",
        if: [
          {
            key: "TH_19",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_19",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_20",
        label: "Have you ever used Gabapentin?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_20a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_20",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_20",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_20b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_20",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_20",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_20c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_20",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_20",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_20d",
        label: "Highest dose",
        if: [
          {
            key: "TH_20",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_20",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_21",
        label: "Have you ever used Lorazepam?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_21a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_21",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_21",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_21b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_21",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_21",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_21c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_21",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_21",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_21d",
        label: "Highest dose",
        if: [
          {
            key: "TH_21",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_21",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_22",
        label: "Have you ever used Propanol?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_22a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_22",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_22",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_22b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_22",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_22",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_22c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_22",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_22",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_22d",
        label: "Highest dose",
        if: [
          {
            key: "TH_22",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_22",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_23",
        label: "Have you ever used Pregabalin?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_23a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_23",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_23",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_23b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_23",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_23",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_23c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_23",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_23",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_23d",
        label: "Highest dose",
        if: [
          {
            key: "TH_23",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_23",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24",
        label: "Have you ever used Aripripazolo?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_24a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_24d",
        label: "Highest dose",
        if: [
          {
            key: "TH_24",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_24",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25",
        label: "Have you ever used Clozapine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_25a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_25d",
        label: "Highest dose",
        if: [
          {
            key: "TH_25",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_25",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26",
        label: "Have you ever used Haloperidol?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_26a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_26d",
        label: "Highest dose",
        if: [
          {
            key: "TH_26",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_26",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27",
        label: "Have you ever used Olanzapine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_27a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_27d",
        label: "Highest dose",
        if: [
          {
            key: "TH_27",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_27",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28",
        label: "Have you ever used Perphenazine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_28a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_28d",
        label: "Highest dose",
        if: [
          {
            key: "TH_28",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_28",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29",
        label: "Have you ever used Quetiapine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_29a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_29d",
        label: "Highest dose",
        if: [
          {
            key: "TH_29",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_29",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30",
        label: "Have you ever used Risperidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_30a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_30d",
        label: "Highest dose",
        if: [
          {
            key: "TH_30",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_30",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31",
        label: "Have you ever used Ziprasidone?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_31a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_31d",
        label: "Highest dose",
        if: [
          {
            key: "TH_31",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_31",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32",
        label: "Have you ever used Atomoxetine?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_32a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_32d",
        label: "Highest dose",
        if: [
          {
            key: "TH_32",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_32",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33",
        label: "Have you ever used Methylphenidate?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_33a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_33d",
        label: "Highest dose",
        if: [
          {
            key: "TH_33",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_33",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34",
        label: "Have you ever used ECT?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_34a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_34d",
        label: "Highest dose",
        if: [
          {
            key: "TH_34",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_34",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35",
        label: "Have you ever used Bright Light?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never Used"
          },
          {
            value: 2,
            label: "Current Use"
          },
          {
            value: 3,
            label: "Past Use"
          }
        ]
      },
      {
        key: "TH_35a",
        label: "How long did you use it?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35b",
        label: "How well did it work?",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35c",
        label: "Unwanted effect",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      },
      {
        key: "TH_35d",
        label: "Highest dose",
        if: [
          {
            key: "TH_35",
            comparator: "=",
            value: 3
          },
          {
            key: "TH_35",
            comparator: "=",
            value: 2
          }
        ],
        type: "free-text"
      }
    ]
  },
  {
    type: "BD info (LICQ)",
    name: "LICQ",
    codiceDataCompletamento: "DATE_LICQ",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_LICQ",
        label: "Date",
        type: "date"
      },
      {
        key: "LICQ_1",
        label:
          "Have you ever experienced a period of mood elevation that you consider abnormal",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_2",
        label: "Have you ever been diagnosed with a manic episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_3",
        label: "Do you believe you have had a manic episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_4",
        label:
          "Does any friend, relative or clinician think you had a manic episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_5",
        label: "Did you ever receive treatment for a manic episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_6_",
        label:
          "Did you go to the hospital because of things that happened during that time or symptoms\nrelated to that episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_7",
        label:
          "The most extreme episode of mood elevation (mania or hypomania) in my life occurred in (Year)",
        if: null,
        type: "free-text"
      },
      {
        key: "LICQ_8",
        label:
          "Considering the most severe week during that episode, HOW MANY DAYS did you experience feeling euphoric even briefly ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: "1 or 2"
          },
          {
            value: 3,
            label: "3 or 4"
          },
          {
            value: 4,
            label: "5 or more"
          }
        ]
      },
      {
        key: "LICQ_9",
        label:
          "Considering the most severe week during that episode, HOW MANY DAYS did you feel like you could do much more than you are ordinarily capable of doing? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: "1 or 2"
          },
          {
            value: 3,
            label: "3 or 4"
          },
          {
            value: 4,
            label: "5 or more"
          }
        ]
      },
      {
        key: "LICQ_10",
        label:
          "Considering the most severe week during that episode, HOW MANY DAYS did you fexperience feeling more irritable, or short tempered than is usual for you?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: "1 or 2"
          },
          {
            value: 3,
            label: "3 or 4"
          },
          {
            value: 4,
            label: "5 or more"
          }
        ]
      },
      {
        key: "LICQ_11",
        label:
          "During that week when your symptoms were most severe, did you feel more talented, important, smart or attractive compared to usual?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_12",
        label:
          "During that week when your symptoms were most severe, did you do anything other people considered arrogant?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_13",
        label:
          "During that week when your symptoms were most severe, did you experience racing thoughts? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_14",
        label:
          "During that week when your symptoms were most severe, did you sleep less than usual?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_15",
        label:
          "During that week when your symptoms were most severe, did you talk excessively or speak so fast that people had trouble understanding you?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_16",
        label:
          "During that week when your symptoms were most severe, did you find that any little distraction caused you to lose track of what you were saying or doing?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_17",
        label:
          "During that week when your symptoms were most severe, did you did you start new projects or make so many plans that people became concerned about \n whether you were taking on too much?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_18",
        label:
          "During that week when your symptoms were most severe, did you get involved in inappropriate relationships or do anything sexual that is unusual for you?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_19",
        label:
          "During that week when your symptoms were most severe, did you do things that were unusually risky or would have caused you trouble if other people found out?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_20",
        label:
          "If a friend had been with you during the week when your symptoms were most severe, would they have been able to tell something was wrong?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_21",
        label:
          "If a friend had been with you during the week when your symptoms were most severe, would they have seen any times you were restless, fidgeting or pacing?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_22",
        label:
          "Did what happened when your mood was elevated cause any problems for you, your family \nor friends?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_23",
        label: " I went to the hospital",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_24",
        label:
          " I could not work at my job, or if I had been employed, I would not have been able to work at \na job",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_25",
        label: "I had a physical confrontation with someone",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_26",
        label: "I had trouble because of excessive spending",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_27",
        label: "I had a disciplinary action at work or school",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_28",
        label: "I had a traffic accident",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_29",
        label:
          "I did something that would have caused me legal trouble, if I had been caught",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_30",
        label:
          " I did something that other people would consider excessive foolish or risky",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_31",
        label: "I did something that I look back on as very bad judgment",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_32",
        label:
          "Is there a written record showing the treatment you had for this episode? Please check all that apply",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, in hospital records"
          },
          {
            value: 2,
            label: "Yes, in doctor’s records"
          },
          {
            value: 3,
            label: "Yes, in report from legal authorities"
          },
          {
            value: 4,
            label: "No, I was not treated"
          },
          {
            value: 5,
            label: "No, I was treated, but no records are available"
          }
        ]
      },
      {
        key: "LICQ_33",
        label:
          "How old were you the first time you had a period of depression that lasted nearly every day for at least \ntwo weeks?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Age 14 or younger"
          },
          {
            value: 2,
            label: "Age 15-19"
          },
          {
            value: 3,
            label: "Age 20-30"
          },
          {
            value: 4,
            label: "Age 31- 45"
          },
          {
            value: 5,
            label: "Age 46 or older"
          },
          {
            value: 6,
            label: "I never had an episode of depression"
          }
        ]
      },
      {
        key: "LICQ_34",
        label:
          "How old were you the first time you had a period of abnormal mood elevation or excessive irritability \n(mania or hypomania) that lasted most days over a period of a week or more?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Age 14 or younger"
          },
          {
            value: 2,
            label: "Age 15-19"
          },
          {
            value: 3,
            label: "Age 20-30"
          },
          {
            value: 4,
            label: "Age 31- 45"
          },
          {
            value: 5,
            label: "Age 46 or older"
          },
          {
            value: 6,
            label: "I never had an episode of abnormal mood elevation"
          }
        ]
      },
      {
        key: "LICQ_35",
        label:
          "How old were you the first time you were treated for a psychiatric problem other than depression or mood elevation? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Age 14 or younger"
          },
          {
            value: 2,
            label: "Age 15-19"
          },
          {
            value: 3,
            label: "Age 20-30"
          },
          {
            value: 4,
            label: "Age 31- 45"
          },
          {
            value: 5,
            label: "Age 46 or older"
          },
          {
            value: 6,
            label: "I never had treatment for any other psychiatric condition"
          }
        ]
      },
      {
        key: "LICQ_35a",
        label:
          "How old were you the first time you were pharmacogically treated for a psychiatric problem other than depression or mood elevation? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Age 14 or younger"
          },
          {
            value: 2,
            label: "Age 15-19"
          },
          {
            value: 3,
            label: "Age 20-30"
          },
          {
            value: 4,
            label: "Age 31- 45"
          },
          {
            value: 5,
            label: "Age 46 or older"
          },
          {
            value: 6,
            label: "I never had treatment for any other psychiatric condition"
          }
        ]
      },
      {
        key: "LICQ_36",
        label: "How many separate episodes of depression have you had?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: "2 or 3"
          },
          {
            value: 4,
            label: "From 4 to 9"
          },
          {
            value: 5,
            label: "10 or more"
          },
          {
            value: 6,
            label: "I don't know"
          }
        ]
      },
      {
        key: "LICQ_36a",
        label:
          "During these episodes, Have you ever had hallucinations or delusions?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_36b",
        label: "Number of depressive episodes with psychotic features",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: "more"
          }
        ]
      },
      {
        key: "LICQ_37",
        label:
          " How many separate episodes of abnormal mood elevation (mania or hypomania) have you had?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: "2 or 3"
          },
          {
            value: 4,
            label: "From 4 to 9"
          },
          {
            value: 5,
            label: "10 or more"
          },
          {
            value: 6,
            label: "I don't know"
          }
        ]
      },
      {
        key: "LICQ_38",
        label:
          "During these episodes,Have you ever had hallucinations or delusions?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_38a",
        label: "Number of manic/hypomanic episodes with psychotic features",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          },
          {
            value: 4,
            label: 4
          },
          {
            value: 5,
            label: 5
          },
          {
            value: 6,
            label: "more"
          }
        ]
      },
      {
        key: "LICQ_38b",
        label: "How many mixed episodes have you had?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None"
          },
          {
            value: 2,
            label: 1
          },
          {
            value: 3,
            label: "2 or 3"
          },
          {
            value: 4,
            label: "25-52 weeks"
          },
          {
            value: 5,
            label: "More than 1 year"
          }
        ]
      },
      {
        key: "LICQ_38c",
        label:
          "During these episodes, Have you ever had hallucinations or delusions?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 4,
            label:
              "I have never been more than halfway back to normal (several symptoms and \nseldom able to function adequately at home or work)"
          }
        ]
      },
      {
        key: "LICQ_38d",
        label: "Number of mixed episodes with psychotic features",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "LICQ_39",
        label:
          "Over the past two years what is the longest period you have been consistently well?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Less than 14 days"
          },
          {
            value: 2,
            label: "2-8 weeks"
          },
          {
            value: 3,
            label: "9-24 weeks"
          }
        ]
      },
      {
        key: "LICQ_40",
        label: " During your best month between episodes",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "I was completely back to normal (no symptoms and fully able to function at home and/or work)"
          },
          {
            value: 2,
            label:
              "I am nearly back to normal (a few symptoms but able to function adequately at home and/or work)"
          },
          {
            value: 3,
            label:
              "I am better than halfway back to normal but still have symptoms of depression or mood elevation (some symptoms and mostly able to function adequately at home or work"
          },
          {
            value: 4,
            label:
              "I don’t have any information about my blood relatives (e.g. I was adopted)"
          }
        ]
      },
      {
        key: "LICQ_41",
        label: "To what extent has alcohol use caused you problems?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Not at all (I never had problems due to alcohol)."
          },
          {
            value: 2,
            label: "At times (I have had problems due to alcohol use or abuse)."
          },
          {
            value: 3,
            label:
              "Most months (I have had problems due to alcohol use or abuse)"
          }
        ]
      },
      {
        key: "LICQ_42",
        label: " To what extent has drug use caused you problems?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label:
              "Not at all (I never had problems due to abuse of any medication)."
          },
          {
            value: 2,
            label: "At times (I have had problems due to drug use or abuse)."
          },
          {
            value: 3,
            label: "Most months (I have had problems due to drug use or abuse)"
          }
        ]
      },
      {
        key: "LICQ_42a",
        label: "Has there ever been an induced episode?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_42b",
        label: "Number of induced episoded",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "LICQ_43",
        label: "My blood relatives include",
        if: null,
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 1,
            label: "Sisters"
          },
          {
            value: 2,
            label: "Brothers"
          },
          {
            value: 3,
            label: "Children"
          },
          {
            value: 4,
            label: "None"
          },
          {
            value: 5,
            label: "Parents"
          }
        ]
      },
      {
        key: "LICQ_44",
        label:
          "Have any of your parents been treated for any psychiatric illness?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_45",
        label:
          "Have any of your siblings been treated for any psychiatric illness?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_46",
        label:
          "Have any of your children been treated for any psychiatric illness?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_47",
        label:
          "Have any of your other blood relatives been treated for any psychiatric illness?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_48",
        label: "Do any of your parents have Bipolar Disorder?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_49",
        label: "Do any of your siblings have Bipolar Disorder?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_50",
        label: "Do any of your children  have Bipolar Disorder?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_51",
        label: "Do any of your other blood relatives have Bipolar Disorder?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_52",
        label:
          "Do any of your parents have Unipolar Disorder? (also called Major \nDepressive Disorder)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_53",
        label:
          "Do any of your siblings have Unipolar Disorder? (also called Major \nDepressive Disorder)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_54",
        label:
          "Do any of your children  have Unipolar Disorder? (also called Major \nDepressive Disorder)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_55",
        label:
          "Do any of your other blood relatives have Unipolar Disorder? (also called Major \nDepressive Disorder)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_56",
        label: "Do any of your parents have Alcohol or drug abuse problems?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_57",
        label: "Do any of your siblings have Alcohol or drug abuse problems?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_58",
        label: "Do any of your children have Alcohol or drug abuse problems?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "LICQ_59",
        label:
          " Do any of your blood relatives have Alcohol or drug abuse problems? ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      }
    ]
  },
  {
    type: "FAGERSTROM",
    name: " Fagerstrom Test",
    codiceDataCompletamento: "DATA_fumo",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_fumo",
        label: "Date",
        type: "date"
      },
      {
        key: "fumo_0",
        label: "Do you smoke cigarettes?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes"
          },
          {
            value: 1,
            label: "No"
          },
          {
            value: 2,
            label: "Sometimes"
          }
        ]
      },
      {
        key: "fumo_1",
        label: "How long after waking up do you light your first cigarette?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Within 5 minutes = 3"
          },
          {
            value: 1,
            label: "After 6 - 30 minutes =2"
          },
          {
            value: 2,
            label: "After 31 - 60 minutes = 1"
          },
          {
            value: 3,
            label: "After 60 minutes= 0"
          }
        ]
      },
      {
        key: "fumo_2",
        label:
          "Do you find it hard not to smoke in places where it is prohibited?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "fumo_3",
        label: "Which cigarette costs you the most to give up?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "The first one in the morning = 1"
          },
          {
            value: 1,
            label: "All others= 0"
          }
        ]
      },
      {
        key: "fumo_4",
        label: "How many cigarettes do you smoke per day?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "10 or less = 0"
          },
          {
            value: 1,
            label: "11 to 20 = 1"
          },
          {
            value: 2,
            label: "21 to 30 = 2"
          },
          {
            value: 3,
            label: "31 or more = 3"
          }
        ]
      },
      {
        key: "fumo_5",
        label:
          "Do you smoke more frequently during the first hour after waking up than during the rest of the day?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "fumo_6",
        label:
          "Does you smoke even when you are so sick that you spend most of the day in bed?",
        if: [
          {
            key: "fumo_0",
            comparator: "=",
            value: 0
          },
          {
            key: "fumo_0",
            comparator: "=",
            value: 2
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "fumo_7",
        label: "Total score ",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "fumo_1",
              map: {
                "0": 3,
                "1": 2,
                "2": 1,
                "3": 0
              }
            },
            {
              key: "fumo_2",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "fumo_3",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "fumo_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "fumo_5",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "fumo_6",
              map: {
                "0": 1,
                "1": 0
              }
            }
          ],
          specialCases: [
            {
              if: [
                {
                  key: "fumo_0",
                  value: 1
                }
              ],
              result: 0
            }
          ]
        }
      }
    ]
  },
  {
    type: "AUDIT",
    name: "AUDIT Test",
    codiceDataCompletamento: "DATA_alcol",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_alcol",
        label: "Date",
        type: "date"
      },
      {
        key: "alcol_0",
        label: "How often do you drink a beverage that contains alcohol?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Monthly or less =1"
          },
          {
            value: 2,
            label: "2-4 times a months =2"
          },
          {
            value: 3,
            label: "2-3 times a week = 3"
          },
          {
            value: 4,
            label: "4 or more times a week = 4"
          }
        ]
      },
      {
        key: "alcol_1",
        label:
          "How many drinks that cointain alcohol do you have a typical day when drinking?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "1-2 unit = 0"
          },
          {
            value: 1,
            label: "3-4 unit =1"
          },
          {
            value: 2,
            label: "5-6 unit = 2"
          },
          {
            value: 3,
            label: "7-8 unit = 3"
          },
          {
            value: 4,
            label: "10 or more unit = 4"
          }
        ]
      },
      {
        key: "alcol_2",
        label: "How often do you have six or more drinks on one occasion?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily = 4"
          }
        ]
      },
      {
        key: "alcol_3",
        label:
          "During the past year, how often have you found that you were not able to stop drinking once you have started?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily = 4"
          }
        ]
      },
      {
        key: "alcol_4",
        label:
          "During the past year, how often have you failed to do what was normally expected of you because of drinking?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily = 4"
          }
        ]
      },
      {
        key: "alcol_5",
        label:
          "During the past year, how often have you needed a drink in the morning to get yourself going after a heavy drinking-session?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily = 4"
          }
        ]
      },
      {
        key: "alcol_6",
        label:
          "During the past year, how often have you had a feeling of guilt or remorse after drinking?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily= 4"
          }
        ]
      },
      {
        key: "alcol_7",
        label:
          "During the past year, how often have you been unable to remember what happaned the night before, becuase you have been drinking?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never = 0"
          },
          {
            value: 1,
            label: "Less than monthly =1"
          },
          {
            value: 2,
            label: "Monthly = 2"
          },
          {
            value: 3,
            label: "Weekly = 3"
          },
          {
            value: 4,
            label: "Daily/ almost daily = 4"
          }
        ]
      },
      {
        key: "alcol_8",
        label:
          "Have you or someone else been injured as a result of your drinking?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No = 0"
          },
          {
            value: 1,
            label: "Yes, but not in the past year =2"
          },
          {
            value: 2,
            label: "Yes, during the past year = 4"
          }
        ]
      },
      {
        key: "alcol_9",
        label:
          "Has a relative or friend, doctor, or another health-worker been concerned about your drinking or suggested you to cut down?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No = 0"
          },
          {
            value: 1,
            label: "Yes, but not in the past year =2"
          },
          {
            value: 2,
            label: "Yes, during the past year = 4"
          }
        ]
      },
      {
        key: "alcol_10",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "alcol_0",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "alcol_8",
              map: {
                "0": 0,
                "1": 2,
                "2": 4
              }
            },
            {
              key: "alcol_9",
              map: {
                "0": 0,
                "1": 2,
                "2": 4
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "DAST",
    name: "DAST Test",
    codiceDataCompletamento: "DATA_drugs",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_drugs",
        label: "Date",
        type: "date"
      },
      {
        key: "drugs_0",
        label: "Have you ever used any drugs?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes"
          },
          {
            value: 1,
            label: "No"
          }
        ]
      },
      {
        key: "drugs_1",
        label: "Kind of drugs",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button-multiple-answers",
        values: [
          {
            value: 0,
            label: "methamphetamines (speed, crystal)"
          },
          {
            value: 1,
            label: "cannabis (marijuana, pot)"
          },
          {
            value: 2,
            label: "inhalants (paint thinner, aerosol, glue)"
          },
          {
            value: 3,
            label: "tranquilizers (valium)"
          },
          {
            value: 4,
            label: "cocaine"
          },
          {
            value: 5,
            label: "narcotics (heroin, oxycodone, methadone, etc."
          },
          {
            value: 6,
            label: "hallucinogens (LSD, mushrooms)"
          },
          {
            value: 7,
            label: "other"
          }
        ]
      },
      {
        key: "drugs_1_2",
        label: 'Specify "Other"',
        if: [
          {
            key: "drugs_1",
            comparator: "=",
            value: 7,
            isMulti: true
          }
        ],
        type: "free-text"
      },
      {
        key: "drugs_2",
        label: "How often have you used these drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Monthly or less"
          },
          {
            value: 1,
            label: "Weekly"
          },
          {
            value: 2,
            label: "Daily/ almost daily"
          }
        ]
      },
      {
        key: "drugs_3",
        label:
          "Have you used drugs other than those required for medical reasons?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_4",
        label: "Do you abuse more than one drug at a time?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_5",
        label: "Are you always able to stop using drugs when you want to?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =0"
          },
          {
            value: 1,
            label: "No= 1"
          }
        ]
      },
      {
        key: "drugs_6",
        label:
          "Have you ever had blackouts or flashbacks as a result of drug use?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_7",
        label: "Do you ever feel bad or guilty about your drug use?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_8",
        label:
          "Does your spouse (or parents) ever complain about your involvement with drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_9",
        label: "Have you neglected your family because of your use of drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_10",
        label:
          ".Have you engaged in illegal activities in order to obtain drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_11",
        label:
          "Have you ever experienced withdrawal symptoms (felt sick) when you stopped taking drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_12",
        label:
          "Have you had medical problems as a result of your drug use (e.g. memory loss, hepatitis, convulsions, bleeding)?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Yes =1"
          },
          {
            value: 1,
            label: "No = 0"
          }
        ]
      },
      {
        key: "drugs_13",
        label: "Have you ever injected drugs?",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never"
          },
          {
            value: 1,
            label: "Yes, in the past 90 days"
          },
          {
            value: 2,
            label: "Yes, more than 90 days ago"
          }
        ]
      },
      {
        key: "drugs_14",
        label: "Have you ever been in treatment for substance abuse? ",
        if: [
          {
            key: "drugs_0",
            comparator: "=",
            value: 0
          }
        ],
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Never"
          },
          {
            value: 1,
            label: "currently"
          },
          {
            value: 2,
            label: "in the past"
          }
        ]
      },
      {
        key: "drugs_15",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "drugs_3",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_4",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_5",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "drugs_6",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_7",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_8",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_9",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_10",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_11",
              map: {
                "0": 1,
                "1": 0
              }
            },
            {
              key: "drugs_12",
              map: {
                "0": 1,
                "1": 0
              }
            }
          ],
          specialCases: [
            {
              if: [
                {
                  key: "drugs_0",
                  value: 1
                }
              ],
              result: 0
            }
          ]
        }
      }
    ]
  },
  {
    type: "MMSE",
    name: "MINI MENTAL STATE EXAMINATION (MMSE)",
    codiceDataCompletamento: "DATA_MMSE",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_MMSE",
        label: "Date",
        type: "date"
      },
      {
        key: "MMSE_01",
        label: "1) ORIENTATION",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 10
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_02",
        label: "2) REGISTRATION",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 3
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_03",
        label: "3) ATTENTION E CALCOLO",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 5
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_04",
        label: "4) RECALL AND CALCULATION",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 3
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_05",
        label: "5) LANGUAGE",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 8
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_06",
        label: "6) CONSTRUCTIVE PRAXIS",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 1
        },
        step: "any",
        auto: null
      },
      {
        key: "MMSE_07",
        label: "TOTAL RAW SCORE (total raw score is calculated automatically)",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: {
          sum: [
            "MMSE_01",
            "MMSE_02",
            "MMSE_03",
            "MMSE_04",
            "MMSE_05",
            "MMSE_06"
          ]
        }
      },
      {
        key: "MMSE_08",
        label: "CORRECT SCORE (enter correct score)",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 33
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "CTQ",
    name: "CTQ questionnaire",
    codiceDataCompletamento: "DATA_CTQ",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_CTQ",
        label: "Date",
        type: "date"
      },
      {
        key: "CTQ_0",
        label: "1. I did not have enough to eat",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True=1"
          },
          {
            value: 2,
            label: "Rarely True=2"
          },
          {
            value: 3,
            label: "Sometimes True=3"
          },
          {
            value: 4,
            label: "Often True=4"
          },
          {
            value: 5,
            label: "Very Often True=5"
          }
        ]
      },
      {
        key: "CTQ_1",
        label: "2. I had someone to take care of me and protect \nme",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_2",
        label: "3. I’ve been called “stupid,” “lazy,” and/or “ugly",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_3",
        label: "4.My parents were too drunk/high to take care of \nme",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_4",
        label: "5. Someone helped me feel important",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_5",
        label: " 6. I had to wear dirty clothes",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_6",
        label: "7. I felt loved",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_7",
        label:
          "8.  I felt/thought that my parents wished I had never \nbeen born",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_8",
        label: "9. I got hit so hard that I had to see a doctor",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_9",
        label: "10. There is nothing I want to change in my family",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_10",
        label: "11. I’ve been hit so hard that it left bruises and \nmarks",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_11",
        label:
          "12.  I was punished with a belt, board, cord, or \nanother hard object",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_12",
        label: "13. My family looked out for each other",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_13",
        label: "14. My family said hurtful or insulting things to me",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_14",
        label: "15. I was physically abused",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_15",
        label: "16. I had a perfect childhood ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_16",
        label:
          "17.  I got hit badly and it was noticed by a teacher, neighbor, and/or doctor",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_17",
        label: "18. Someone in my family hated me",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_18",
        label: "19. My family felt close to each other",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_19",
        label:
          "20. Someone tried to touch me in a sexual way or tried to make me touch them",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_20",
        label:
          "21. Someone threatened to hurt me unless I did something sexual with them",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_21",
        label: "22. I have the best family in the world",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_22",
        label:
          "23. Someone made me try to do sexual things/watch sexual things",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_23",
        label: "24. Someone molested me.",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_24",
        label: "25. I was emotionally abused",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_25",
        label:
          "26. Someone took me to see the doctor when I need to/if I needed to",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_26",
        label: "27. I was sexually abused",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_27",
        label: "28. My family gave me strenght and support",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Never True"
          },
          {
            value: 2,
            label: "Rarely True"
          },
          {
            value: 3,
            label: "Sometimes True"
          },
          {
            value: 4,
            label: "Often True"
          },
          {
            value: 5,
            label: "Very Often True"
          }
        ]
      },
      {
        key: "CTQ_29",
        label: "EMOTIONAL ABUSE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_2",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_16",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "CTQ_30",
        label: "PHYSICAL ABUSE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_14",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_17",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "CTQ_31",
        label: "SEXUAL ABUSE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_19",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_20",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_22",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_26",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "CTQ_32",
        label: "EMOTIONAL NEGLECT",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_4",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_6",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_12",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_18",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_27",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            }
          ]
        }
      },
      {
        key: "CTQ_33",
        label: "PHYSICAL NEGLECT",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_1",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_25",
              map: {
                "1": 4,
                "2": 3,
                "3": 2,
                "4": 1,
                "5": 1
              }
            },
            {
              key: "CTQ_0",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      },
      {
        key: "CTQ_34",
        label: "Minimization/Denial (M/D)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "CTQ_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_15",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "CTQ_21",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "PSQI",
    name: "PSQI questionnaire",
    codiceDataCompletamento: "DATA_PSQI",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_PSQI",
        label: "Date",
        type: "date"
      },
      {
        key: "PSQI_0",
        label:
          "The following questions relate to your usual sleep habits during the past month only. Your answers should indicate the most accurate reply for the majority of days and nights in the past month. Please answer all questions.",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_1",
        label:
          "1. During the past month, what time have you usually gone to bed at night",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_2",
        label:
          "2. During the past month, how long (in minutes) has it usually taken you to fall asleep each night?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_3",
        label:
          "3. During the past month, what time have you usually gotten up in the morning?",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_4",
        label:
          "4. During the past month, how many hours of actual sleep did you get at night? (This may be different than the number of hours you spent in bed).",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_5",
        label:
          "5. During the past month, how often have you had trouble sleeping because you:",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_6",
        label: "a. Cannot get to sleep within 30 minutes",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month = 0"
          },
          {
            value: 1,
            label: "Less than once a week = 1"
          },
          {
            value: 2,
            label: "Once or twice a week =2"
          },
          {
            value: 3,
            label: "three or more times a week = 3"
          }
        ]
      },
      {
        key: "PSQI_7",
        label: "b. Wake up in the middle of the night or early morning",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_8",
        label: "c. Have to get up to use the bathroom",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_9",
        label: "d. Cannot breathe comfortably",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_10",
        label: "e. Cough or snore loudly",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_11",
        label: "f. Feel too cold",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_12",
        label: "g. Feel too hot",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_13",
        label: "h. Had bad dreams",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_14",
        label: "i. Have pain",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_15",
        label: "j. Other reason(s), please describe",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_16",
        label:
          "How often during the past month have you had trouble sleeping because of this?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_17",
        label:
          "6. During the past month, how would you rate your sleep quality overall",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Very good"
          },
          {
            value: 1,
            label: "Fairly good"
          },
          {
            value: 2,
            label: "Fairly bad"
          },
          {
            value: 3,
            label: "Very bad"
          }
        ]
      },
      {
        key: "PSQI_18",
        label:
          '7. During the past month, how often have you taken medicine to help you sleep (prescribed or \n"over the counter")',
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_19",
        label:
          "8. During the past month, how often have you had trouble staying awake while driving, eating meals, or engaging in social activity",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_20",
        label:
          "9. During the past month, how much of a problem has it been for you to keep up enough enthusiasm to get things done",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No problem at all"
          },
          {
            value: 1,
            label: "Only a very slight problem"
          },
          {
            value: 2,
            label: "Somewhat of a problem"
          },
          {
            value: 3,
            label: "A very big problem"
          }
        ]
      },
      {
        key: "PSQI_21",
        label: "10. Do you have a bed partner or room mate",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "No bed partner or room mate"
          },
          {
            value: 1,
            label: "Partner/romm mate in other room"
          },
          {
            value: 2,
            label: "Partner in same room, but not same bed"
          },
          {
            value: 3,
            label: "Partner in same bed"
          }
        ]
      },
      {
        key: "PSQI_22",
        label:
          "If you have a room mate or bed partner, ask him/her how often in the past month you have had",
        if: null,
        type: "description"
      },
      {
        key: "PSQI_23",
        label: "a. Loud snoring",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_24",
        label: "b. Long pauses between breaths while asleep",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_25",
        label: "c. Legs twitching or jerking while you sleep",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_26",
        label: "d. Episodes of disorientation or confusion during slee",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_27",
        label: "e. Other restlessness while you sleep; please describe…",
        if: null,
        type: "free-text"
      },
      {
        key: "PSQI_28",
        label:
          "How often during the past month have you had other restlessness while you sleep?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "Not during the past month"
          },
          {
            value: 1,
            label: "Less than once a week"
          },
          {
            value: 2,
            label: "Once or twice a week"
          },
          {
            value: 3,
            label: "three or more times a week"
          }
        ]
      },
      {
        key: "PSQI_Durata",
        label:
          "DURATION OF SLEEP: IF Q4 > 7, THEN set value to 0\n\t\t\tIF Q4 < 7 and > 6, THEN set value to 1\n\t\t\tIF Q4 < 6 and > 5, THEN set value to 2\n\t\t\tIF Q4 < 5, THEN set value to 3\nMinimum Score = 0 (better); Maximum Score = 3 (worse)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_ PSQIDISTB",
        label:
          "SLEEP DISTURBANCE: IF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) = 0, THEN set value to 0\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 1 and < 9, THEN set value to 1\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 9 and < 18, THEN set value to 2\nIF Q5b + Q5c + Q5d + Q5e + Q5f + Q5g + Q5h + Q5i + Q5j (IF Q5JCOM is null or Q5j is null, set the value of Q5j to 0) > 18, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_SLEEP LATENCY",
        label:
          "SLEEP LATENCY: First, recode Q2 into Q2new thusly:\nIF Q2 > 0 and < 15, THEN set value of Q2new to 0\nIF Q2 > 15 and < 30, THEN set value of Q2new to 1\nIF Q2 > 30 and < 60, THEN set value of Q2new to 2\nIF Q2 > 60, THEN set value of Q2new to 3\nNext\nIF Q5a + Q2new = 0, THEN set value to 0\nIF Q5a + Q2new > 1 and < 2, THEN set value to 1\nIF Q5a + Q2new > 3 and < 4, THEN set value to 2\nIF Q5a + Q2new > 5 and < 6, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_DYSF",
        label:
          "DAY DYSFUNCTION DUE TO SLEEPINESS: IF Q8 + Q9 = 0, THEN set value to 0\n\t\t\tIF Q8 + Q9 > 1 and < 2, THEN set value to 1\n\t\t\tIF Q8 + Q9 > 3 and < 4, THEN set value to 2\n\t\t\tIF Q8 + Q9 > 5 and < 6, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_SE",
        label:
          "SLEEP EFFICIENCY: Diffsec = Difference in seconds between day and time of day Q1 and day Q3\n\t\tDiffhour = Absolute value of diffsec / 3600\n\t             newtib =IF diffhour > 24, then newtib = diffhour – 24\n\t           IF diffhour < 24, THEN newtib = diffhour\n(NOTE, THE ABOVE JUST CALCULATES THE HOURS BETWEEN GNT (Q1) AND GMT (Q3))\n\t\ttmphse = (Q4 / newtib) * 100 IF tmphse > 85, THEN set value to 0\n\t\tIF tmphse < 85 and > 75, THEN set value to 1\n\t\tIF tmphse < 75 and > 65, THEN set value to 2\n\t\tIF tmphse < 65, THEN set value to 3",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_OSP",
        label: "OVERALL SLEEP QUALITY (see Q6)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_MEDS",
        label: "NEED MEDS TO SLEEP (seeQ7)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "PSQI_TOT",
        label: "TOTAL",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "PSQI_Durata",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "PSQI_ PSQIDISTB",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "PSQI_SLEEP LATENCY",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "PSQI_SE",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "PSQI_OSP",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "PSQI_MEDS",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "SF-36",
    name: "SF-36 questionnaire",
    codiceDataCompletamento: "DATA_SF-36",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_SF-36",
        label: "Date",
        type: "date"
      },
      {
        key: "SF-36_0",
        label:
          "Please answer the 36 questions of the Health Survey completely, honestly, and without interruptions majority of days and nights in the past month. Please answer all questions.",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_1",
        label: "In general, would you say your health is",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Excellent=1"
          },
          {
            value: 2,
            label: "Very good=2"
          },
          {
            value: 3,
            label: "Good=3"
          },
          {
            value: 4,
            label: "Fair=4"
          },
          {
            value: 5,
            label: "Poor=5"
          }
        ]
      },
      {
        key: "SF-36_2",
        label:
          "Compared to one year ago, how would you rate your health in general now",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Much better now than one year ago=1"
          },
          {
            value: 2,
            label: "Somewhat better now than one year ago=2"
          },
          {
            value: 3,
            label: "About the same=3"
          },
          {
            value: 4,
            label: "Somewhat worse now than one year ago=4"
          },
          {
            value: 5,
            label: "Much worse than one year ago=5"
          }
        ]
      },
      {
        key: "SF-36_3",
        label:
          "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_4",
        label:
          "Vigorous activities, such as running, lifting heavy objects, participating in strenuous sport",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_5",
        label:
          "Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing gol",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_6",
        label: "Lifting or carrying groceries",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_7",
        label: "Climbing several flights of stair",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_8",
        label: "Climbing one flight of stairs",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_9",
        label: "Bending, kneeling, or stooping",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_10",
        label: "Walking more than a mile",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_11",
        label: "Walking several blocks",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_12",
        label: "Walking one block",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_13",
        label: "Bathing or dressing yourself",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_14",
        label:
          "PHYSICAL HEALTH PROBLEMS:\nDuring the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_15",
        label:
          "Cut down the amount of time you spent on work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes=1"
          },
          {
            value: 2,
            label: "No=2"
          }
        ]
      },
      {
        key: "SF-36_16",
        label: "Accomplished less than you would like",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_17",
        label: "Were limited in the kind of work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_18",
        label:
          "Had difficulty performing the work or other activities (for example, it took extra effort)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_19",
        label:
          "EMOTIONAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_20",
        label:
          "Cut down the amount of time you spent on work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_21",
        label: "Accomplished less than you would like",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_22",
        label: "Didn't do work or other activities as carefully as usual",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_23",
        label:
          "SOCIAL ACTIVITIES: Emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Not at all=1"
          },
          {
            value: 2,
            label: "Slightly=2"
          },
          {
            value: 3,
            label: "Moderately=3"
          },
          {
            value: 4,
            label: "Severe=4"
          },
          {
            value: 5,
            label: "Very severe=5"
          }
        ]
      },
      {
        key: "SF-36_24",
        label:
          "PAIN: How much bodily pain have you had during the past 4 weeks?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None=1"
          },
          {
            value: 2,
            label: "Very Mild=2"
          },
          {
            value: 3,
            label: "Mild=3"
          },
          {
            value: 4,
            label: "Moderate=4"
          },
          {
            value: 5,
            label: "Severe=5"
          }
        ]
      },
      {
        key: "SF-36_25",
        label:
          "During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Not at all=1"
          },
          {
            value: 2,
            label: "A little bit=2"
          },
          {
            value: 3,
            label: "Moderately=3"
          },
          {
            value: 4,
            label: "Quite a bit=4"
          },
          {
            value: 5,
            label: "Extremely=5"
          }
        ]
      },
      {
        key: "SF-36_26",
        label:
          "ENERGY AND EMOTIONS:\nThese questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_27",
        label: "Did you feel full of pep?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time=1"
          },
          {
            value: 2,
            label: "Most of the time=2"
          },
          {
            value: 3,
            label: "A good bit of the time=3"
          },
          {
            value: 4,
            label: "Some of the time=4"
          },
          {
            value: 5,
            label: "A little bit of the time=5"
          }
        ]
      },
      {
        key: "SF-36_28",
        label: "Have you been a very nervous person?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_29",
        label:
          "Have you felt so down in the dumps that nothing could cheer you up?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_30",
        label: "Have you felt calm and peaceful?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_31",
        label: "Did you have a lot of energy?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_32",
        label: "Have you felt downhearted and blue?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_33",
        label: "Did you feel worn out?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_34",
        label: "Have you been a happy person?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_35",
        label: "Did you feel tired?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_36",
        label:
          "SOCIAL ACTIVITIES: During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time=1"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "Some of the time"
          },
          {
            value: 4,
            label: "A little bit of the time"
          },
          {
            value: 5,
            label: "None of the time"
          }
        ]
      },
      {
        key: "SF-36_37",
        label:
          "GENERAL HEALTH: How true or false is each of the following statements for you?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_38",
        label: "I seem to get sick a little easier than other people",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true=1"
          },
          {
            value: 2,
            label: "Mostly True=2"
          },
          {
            value: 3,
            label: "Don't Know=3"
          },
          {
            value: 4,
            label: "Mostly False=4"
          },
          {
            value: 5,
            label: "Definitely False=5"
          }
        ]
      },
      {
        key: "SF-36_39",
        label: "I am as healthy as anybody I know",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_40",
        label: "I expect my health to get worse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_41",
        label: "My health is excellent",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_42",
        label: "PHYSICAL FUNCTIONING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ],
          divideBy: 10
        }
      },
      {
        key: "SF-36_43",
        label: "\nROLE LIMITATION DUE TO PHYSICAL HEALTH",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_15",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_16",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_17",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_18",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_44",
        label: "ROLE LIMITATION DUE TO EMOTIONAL PROBLEMS",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_20",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_21",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_22",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 3
        }
      },
      {
        key: "SF-36_45",
        label: "ENERGY/FATIGUE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_35",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_46",
        label: "EMOTIONAL WELL-BEING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      },
      {
        key: "SF-36_47",
        label: "SOCIAL FUNCTIONING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_36",
              map: {
                "1": 1,
                "2": 2,
                "3": 4,
                "4": 5,
                "5": 6
              }
            },
            {
              key: "SF-36_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_48",
        label: "PAIN",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_49",
        label: "GENERAL HEALTH",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_38",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_39",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_40",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_41",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      }
    ]
  },
  {
    type: "ECI",
    name: "Elixahuser Comorbidity Index",
    codiceDataCompletamento: "DATA_Elixahuser",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_Elixahuser",
        label: "Date",
        type: "date"
      },
      {
        key: "Elixahuser_0",
        label:
          "Below you will find a list of physical disorders. For each disorder there is a specific score to be assigned. Please mark with a cross the disorders from which the participant has suffered in the course of his or her life. The total score is calculated from the sum of the individual scores in the column.",
        if: null,
        type: "description"
      },
      {
        key: "Elixahuser_1",
        label: "1) Myocardial infarction ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_2",
        label: "2) Heart disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_3",
        label: "3) Vascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_4",
        label: "4) Cerebrovascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_5",
        label: "5) Dementia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_6",
        label: "6) Chronic lung diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_7",
        label: "7) Connective tissue diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_8",
        label: "8) Ulcer",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_9",
        label: "9) Mild liver diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_10",
        label: "10) Diabetes (without complications)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_11",
        label: "11) Diabetes with organ damage",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_12",
        label: "12) Ictus",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_13",
        label: "13) Moderate or severe kidney disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_14",
        label: "14) Second non-metastatic tumour",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_15",
        label: "15) Leukaemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_16",
        label: "16) Lymphoma, MM",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_17",
        label: "17) Moderate or severe liver disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 3,
            label: "3= sì"
          }
        ]
      },
      {
        key: "Elixahuser_18",
        label: "18) Second metastatic tumour",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_19",
        label: "19) AIDS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_20",
        label: "Age (in years)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "Elixahuser_21",
        label: "TOTAL RAW SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "Elixahuser_1",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_2",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_3",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_4",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_5",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_6",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_7",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_8",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_9",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_10",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_11",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_12",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_13",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_14",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_15",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_16",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_17",
              map: {
                "0": 0,
                "3": 3
              }
            },
            {
              key: "Elixahuser_18",
              map: {
                "0": 0,
                "6": 6
              }
            },
            {
              key: "Elixahuser_19",
              map: {
                "0": 0,
                "6": 6
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "WHODAS 2.0",
    name: "WHO DISABILITY ASSESSMENT SCHEDULE (WHODAS) ",
    codiceDataCompletamento: "DATA_11",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_11",
        label: "Date",
        type: "date"
      },
      {
        key: "WHODAS_0",
        label:
          "This questionnaire explores difficulties that are related to health problems. Health problems are illnesses or other ailments that may be of short or long duration, trauma, mental or emotional problems and problems with alcohol or drugs.\nThink back over the last 30 days and answer the questions thinking about how much difficulty you had in performing the activities listed below.\nFor each question, you should indicate only one number for each item.",
        if: null,
        type: "description"
      },
      {
        key: "WHODAS_1",
        label: "S1) Standing for a long time, such as for 30 minutes? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_2",
        label:
          "S2) Taking care of home and family as far as\nis your responsibility?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_3",
        label: "S3) Learning new things, such as how to get somewhere new?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_4",
        label:
          "S4) How many problems did you have in participating in community activities (e.g. festivals, religious or other activities) like everyone else?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_5",
        label:
          "S5) How emotionally involved were you with your health problem?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_6",
        label: "S6) Concentrate on doing something for ten minutes?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_7",
        label: "S7) Walking a long distance, like a kilometre?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_8",
        label: "S8) Wash your whole body?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_9",
        label: "S9) Dress up?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_10",
        label: "S10)  Interacting with people he does not know?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_11",
        label: "S11) Maintaining a friendship?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_12",
        label: "S12) Carrying out daily work/schooling activities?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_13",
        label:
          "H1) Overall, over the past 30 days, how many days have you experienced these difficulties?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_14",
        label:
          "H2) In the last 30 days, for how many days were you unable to carry out your usual activities or work due to your health condition?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_15",
        label:
          "H3) In the last 30 days, not counting the days when you have been incapacitated, for how many days have you decreased or reduced your usual activities or work due to your health condition?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_16",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "WHODAS_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ],
          divideBy: 12
        }
      }
    ]
  },
  {
    type: "FAST",
    name: "FAST",
    codiceDataCompletamento: "DATA_15",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_15",
        label: "Date",
        type: "date"
      },
      {
        key: "FAST_0",
        label:
          "To what extent is the patient experiencing difficulties in each of the following areas? Ask the\npatient about each of the 24 areas of possible difficulty in functioning and score according to the\nfollowing scale: (0) no difficulty, (1) mild difficulty, (2) moderate difficulty, (3) severe difficulty.",
        if: null,
        type: "description"
      },
      {
        key: "FAST_1",
        label: " Taking responsibility for a household",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_2",
        label: "Living on your own",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_3",
        label: "Doing the shopping",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_4",
        label: "Taking care of yourself (physical aspects, hygiene)",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_5",
        label: " Holding down a paid job",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_6",
        label: " Accomplishing tasks as quickly as necessary",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_7",
        label: "Working in the field in which you were educated",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_8",
        label: " Occupational earnings",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_9",
        label: " Managing the expected work load",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_10",
        label: "Ability to concentrate on a book or film",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_11",
        label: " Ability to make mental calculations",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_12",
        label: " Ability to solve a problem adequately",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_13",
        label: " Ability to remember newly learned names",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_14",
        label: " Ability to learn new information",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_15",
        label: " Managing your own money",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_16",
        label: "Spending money in a balanced way",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_17",
        label: " Maintaining a friendship or friendships",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_18",
        label: "Participating in social activities",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_19",
        label: "Having good relationships with people close to you",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_20",
        label: " Living together with your family",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_21",
        label: " Having satisfactory sexual relationships",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_22",
        label: "Being able to defend your interests",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_23",
        label: " Doing exercise or participating in sport",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_24",
        label: " Having hobbies or personal interests",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: 0
          },
          {
            value: 1,
            label: 1
          },
          {
            value: 2,
            label: 2
          },
          {
            value: 3,
            label: 3
          }
        ]
      },
      {
        key: "FAST_25",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "FAST_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_13",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_14",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_15",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_16",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_17",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_18",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_19",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_20",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_21",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_22",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_23",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "FAST_24",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "EQ5D",
    name: "EQ5D",
    codiceDataCompletamento: "DATE_09",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_09",
        label: "Date",
        type: "date"
      },
      {
        key: "EQ5D_0",
        label:
          "Under each heading, please tick the ONE box that best describes your health TODAY",
        if: null,
        type: "description"
      },
      {
        key: "EQ5D_1",
        label: "MOBILITY",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems in walking about"
          },
          {
            value: 2,
            label: "I have slight problems in walking about"
          },
          {
            value: 3,
            label: "I have moderate problems in walking about"
          },
          {
            value: 4,
            label: "I have severe problems in walking about"
          },
          {
            value: 5,
            label: "I am unable to walk about"
          }
        ]
      },
      {
        key: "EQ5D_2",
        label: "SELF-CARE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems washing or dressing myself"
          },
          {
            value: 2,
            label: "I have slight problems washing or dressing myself"
          },
          {
            value: 3,
            label: "I have moderate problems washing or dressing myself"
          },
          {
            value: 4,
            label: "I have severe problems washing or dressing myself"
          },
          {
            value: 5,
            label: "I am unable to wash or dress myself"
          }
        ]
      },
      {
        key: "EQ5D_3",
        label:
          "USUAL ACTIVITIES (e.g. work, study. Housework. Family or leisure activities)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems doing my usual activities"
          },
          {
            value: 2,
            label: "I have slight problems doing my usual activities"
          },
          {
            value: 3,
            label: "I have moderate problems doing my usual activities"
          },
          {
            value: 4,
            label: "I have severe problems doing my usual activities"
          },
          {
            value: 5,
            label: "I am unable doing my usual activities"
          }
        ]
      },
      {
        key: "EQ5D_4",
        label: "PAIN / DISCOMFORT",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no pain or discomfort"
          },
          {
            value: 2,
            label: "I have slight pain or discomfort"
          },
          {
            value: 3,
            label: "I have moderate pain or discomfort"
          },
          {
            value: 4,
            label: "I have severe pain or discomfort"
          },
          {
            value: 5,
            label: "I have extreme pain or discomfort"
          }
        ]
      },
      {
        key: "EQ5D_5",
        label: "ANXIETY / DEPRESSION",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I am not anxious or depressed"
          },
          {
            value: 2,
            label: "I am slightly anxious or depressed"
          },
          {
            value: 3,
            label: "I am moderately anxious or depressed"
          },
          {
            value: 4,
            label: "I am severely anxious or depressed"
          },
          {
            value: 5,
            label: "I am extremely anxious or depressed"
          }
        ]
      },
      {
        key: "EQ5D_6",
        label: "YOUR HEALTH TODAY",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 100
        },
        step: "any",
        auto: null
      }
    ]
  },
  {
    type: "PEC",
    name: "PEC",
    codiceDataCompletamento: "DATE_PEC",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_PEC",
        label: "Date",
        type: "date"
      },
      {
        key: "PEC_1",
        label:
          "Measured pulse rate by palpating radial pulse or auscultated at apex of heart at least 30 sec",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_2",
        label:
          "Measured blood pressure in one arm, 2 step for initial measurement (not baseline record)\n1.used appropriate size cuff,\n2.placed on inch (2cm) above antecubital space,\n3. inflated cuff 30mmHg above pulse disappearance (palpate or auscultate),\n4. deflated cuff at 2-3mm Hg per sec, until 20-30 below last sound",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_3",
        label:
          "Measured blood pressure in one arm, 1 step for repeat measurement\n1. used appropriate size cuff,\n2. placed on inch (2cm) above antecubital space,\n3. inflated cuff 30mmHg above pulse disappearance (palpate or auscultate),\n4. deflated cuff at 2-3mm Hg per sec, until 20-30 below last sound",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_4",
        label: "Measured Respiratory Rate- at least 30 sec",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_5",
        label:
          "Palpated carotids\n1. at level of thyroid cartilage\n2. right and left (not same time)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_6",
        label:
          "Auscultates Carotids\n1.with bell\n2.bilaterally\n3.with patient breath held",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_7",
        label: "Inspects- chest wall for shape and symmetry",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_8",
        label:
          "Performed percussion of posterior lung fields\n1. cephalad to caudal (top to bottom)\n2. bilaterally\n3. at least three areas (upper lobe to lower lobe)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_9",
        label:
          "Performed auscultation of anterior lung fields bilaterally\n1. upright, seated\n2. at two levels, at least (upper lobe and lower)\n3. both right and left",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_10",
        label:
          "Performed auscultation of posterior lung fields bilaterally\n1. upright, seated\n2. at least three areas (upper lobe to lower lobe)\n3. both right and left",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_11",
        label: "Auscultates lateral lung fields -one area each, right and left",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_12",
        label:
          "Drapes\n1. must be able to listen on skin\n2. attends to patient comfort, through draping",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_13",
        label:
          "Inspects\n1. precordium\n2.  neck veins, carotid pulse\n3. apex of heart (Left Lower Sternal Border, 5th InterCostalSpace)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_14",
        label:
          "Palpates heart\n1. at apex\n2. over right ventricle (Left Lower Sternal Border or epigastric area)\n3. at base (Right Upper Sternal Boarder, Left Upper Sternal Border)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_15",
        label:
          "Auscultates with patient in 3 positions, i.e.\n1. upright, seated\n2. supine\n3. left lateral",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_16",
        label:
          "Auscultates in correct locations (all 4 areas of the heart), i.e.\n1. Upper right sternal border (aortic area)\n2. Upper left sternal border (pulmonic area)\n3. Lower left sternal border (right ventricular area)\n4. Apical Impulse (Left ventricular area)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_17",
        label: "Auscultates with both bell and diaphragm (all 4 areas)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_18",
        label: "Palpates legs for edema with moderate pressure for 5 seconds",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_19",
        label: "Palpates radial pulses, bilaterally",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_20",
        label: "Palpates brachial pulses, bilaterally",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_21",
        label: "Palpates posterior tibial pulses",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      },
      {
        key: "PEC_22",
        label: "Palpates dorsalis pedis pulses",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          },
          {
            value: 3,
            label: "Partial"
          }
        ]
      }
    ]
  },
  {
    type: "BIOMARKERS (T1)",
    name: "PHYSICAL FORM",
    codiceDataCompletamento: "DATE_BIOMARKERS_T1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_BIOMARKERS_T1",
        label: "Date ",
        type: "date"
      },
      {
        key: "BIOMARKERS_T1_0",
        label: "Please state the unit of measurement if it is different",
        if: null,
        type: "description"
      },
      {
        key: "BIOMARKERS_T1_1",
        label: "Adiponectin/leptin ratio ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_2",
        label: "Alanine transaminase (ALT) U/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_3",
        label: "Aspartate Transaminase (AST) U/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_4",
        label: "Hemoglobin g/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_5",
        label: "White blood cell count 10^9/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_6",
        label: "Platelet count  10^9/L ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_7",
        label: "C Peptide ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_8",
        label: "Fasting plasma glucose mg/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_9",
        label: "Gamma-Glutamyl Transferase (GGT) U/L ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_10",
        label: "High-density lipoprotein (HDL)-cholesterol mg/dl ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_11",
        label: "hsCRP mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_12",
        label: "Oxidized LDL ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_13",
        label: "Triglycerides mg/dL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_14",
        label: "Albumin g/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_15",
        label: "Cystatine-C mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_16",
        label: "IL-6 pg/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_17",
        label: "LDL-cholesterol mg/dL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_18",
        label: "Salivary cortisol ng/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_19",
        label: "Serum amyloid A mg/L",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_20",
        label: "Testosterone ng/mL ",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_21",
        label: "Thyroid antibodies (TPOAb) U.I./mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_22",
        label: "Thyroid antibodies (TGAAb) U.I./mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_23",
        label: "TNF-α pg/mL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_24",
        label: "Total Cholesterol mg/dL",
        if: null,
        type: "free-text"
      },
      {
        key: "BIOMARKERS_T1_25",
        label: "Thyroid-stimulating hormone (TSH) micUl/ml ",
        if: null,
        type: "free-text"
      }
    ]
  },
  {
    type: "WHODAS 2.0 (T1)",
    name: "WHO DISABILITY ASSESSMENT SCHEDULE (WHODAS) ",
    codiceDataCompletamento: "DATA_WHODAS_T1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_WHODAS_T1",
        label: "Date",
        type: "date"
      },
      {
        key: "WHODAS_T1_0",
        label:
          "This questionnaire explores difficulties that are related to health problems. Health problems are illnesses or other ailments that may be of short or long duration, trauma, mental or emotional problems and problems with alcohol or drugs.\nThink back over the last 30 days and answer the questions thinking about how much difficulty you had in performing the activities listed below.\nFor each question, you should indicate only one number for each item.",
        if: null,
        type: "description"
      },
      {
        key: "WHODAS_T1_1",
        label: "S1) Standing for a long time, such as for 30 minutes? ",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_2",
        label:
          "S2) Taking care of home and family as far as\nis your responsibility?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_3",
        label: "S3) Learning new things, such as how to get somewhere new?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_4",
        label:
          "S4) How many problems did you have in participating in community activities (e.g. festivals, religious or other activities) like everyone else?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_5",
        label:
          "S5) How emotionally involved were you with your health problem?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_6",
        label: "S6) Concentrate on doing something for ten minutes?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_7",
        label: "S7) Walking a long distance, like a kilometre?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_8",
        label: "S8) Wash your whole body?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_9",
        label: "S9) Dress up?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_10",
        label: "S10)  Interacting with people he does not know?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_11",
        label: "S11) Maintaining a friendship?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_12",
        label: "S12) Carrying out daily work/schooling activities?",
        if: null,
        type: "radio-button-su-riga",
        values: [
          {
            value: 0,
            label: "None"
          },
          {
            value: 1,
            label: "A little"
          },
          {
            value: 2,
            label: "Moderate"
          },
          {
            value: 3,
            label: "A lot"
          },
          {
            value: 4,
            label: "Very much so or I cannot do it"
          }
        ]
      },
      {
        key: "WHODAS_T1_13",
        label:
          "H1) Overall, over the past 30 days, how many days have you experienced these difficulties?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T1_14",
        label:
          "H2) In the last 30 days, for how many days were you unable to carry out your usual activities or work due to your health condition?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T1_15",
        label:
          "H3) In the last 30 days, not counting the days when you have been incapacitated, for how many days have you decreased or reduced your usual activities or work due to your health condition?",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 30
        },
        step: "any",
        auto: null
      },
      {
        key: "WHODAS_T1_16",
        label: "TOTAL SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "WHODAS_T1_1",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_2",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_3",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_4",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_5",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_6",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_7",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_8",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_9",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_10",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_11",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            },
            {
              key: "WHODAS_T1_12",
              map: {
                "0": 0,
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4
              }
            }
          ],
          divideBy: 12
        }
      }
    ]
  },
  {
    type: "SF-36 (T1)",
    name: "SF-36 questionnaire",
    codiceDataCompletamento: "DATA_SF-36_T1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_SF-36_T1",
        label: "Date",
        type: "date"
      },
      {
        key: "SF-36_T1_0",
        label:
          "Please answer the 36 questions of the Health Survey completely, honestly, and without interruptions majority of days and nights in the past month. Please answer all questions.",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_1",
        label: "In general, would you say your health is",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Excellent=1"
          },
          {
            value: 2,
            label: "Very good=2"
          },
          {
            value: 3,
            label: "Good=3"
          },
          {
            value: 4,
            label: "Fair=4"
          },
          {
            value: 5,
            label: "Poor=5"
          }
        ]
      },
      {
        key: "SF-36_T1_2",
        label:
          "Compared to one year ago, how would you rate your health in general now",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Much better now than one year ago=1"
          },
          {
            value: 2,
            label: "Somewhat better now than one year ago=2"
          },
          {
            value: 3,
            label: "About the same=3"
          },
          {
            value: 4,
            label: "Somewhat worse now than one year ago=4"
          },
          {
            value: 5,
            label: "Much worse than one year ago=5"
          }
        ]
      },
      {
        key: "SF-36_T1_3",
        label:
          "LIMITATIONS OF ACTIVITIES: The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_4",
        label:
          "Vigorous activities, such as running, lifting heavy objects, participating in strenuous sport",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_5",
        label:
          "Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing gol",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_6",
        label: "Lifting or carrying groceries",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_7",
        label: "Climbing several flights of stair",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_8",
        label: "Climbing one flight of stairs",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_9",
        label: "Bending, kneeling, or stooping",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_10",
        label: "Walking more than a mile",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_11",
        label: "Walking several blocks",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_12",
        label: "Walking one block",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_13",
        label: "Bathing or dressing yourself",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes, Limited a lot"
          },
          {
            value: 2,
            label: "Yes, Limited a Little"
          },
          {
            value: 3,
            label: "No, Not Limited at all"
          }
        ]
      },
      {
        key: "SF-36_T1_14",
        label:
          "PHYSICAL HEALTH PROBLEMS:\nDuring the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_15",
        label:
          "Cut down the amount of time you spent on work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes=1"
          },
          {
            value: 2,
            label: "No=2"
          }
        ]
      },
      {
        key: "SF-36_T1_16",
        label: "Accomplished less than you would like",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_17",
        label: "Were limited in the kind of work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_18",
        label:
          "Had difficulty performing the work or other activities (for example, it took extra effort)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_19",
        label:
          "EMOTIONAL HEALTH PROBLEMS: During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_20",
        label:
          "Cut down the amount of time you spent on work or other activities",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_21",
        label: "Accomplished less than you would like",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_22",
        label: "Didn't do work or other activities as carefully as usual",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Yes"
          },
          {
            value: 2,
            label: "No"
          }
        ]
      },
      {
        key: "SF-36_T1_23",
        label:
          "SOCIAL ACTIVITIES: Emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Not at all=1"
          },
          {
            value: 2,
            label: "Slightly=2"
          },
          {
            value: 3,
            label: "Moderately=3"
          },
          {
            value: 4,
            label: "Severe=4"
          },
          {
            value: 5,
            label: "Very severe=5"
          }
        ]
      },
      {
        key: "SF-36_T1_24",
        label:
          "PAIN: How much bodily pain have you had during the past 4 weeks?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "None=1"
          },
          {
            value: 2,
            label: "Very Mild=2"
          },
          {
            value: 3,
            label: "Mild=3"
          },
          {
            value: 4,
            label: "Moderate=4"
          },
          {
            value: 5,
            label: "Severe=5"
          }
        ]
      },
      {
        key: "SF-36_T1_25",
        label:
          "During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Not at all=1"
          },
          {
            value: 2,
            label: "A little bit=2"
          },
          {
            value: 3,
            label: "Moderately=3"
          },
          {
            value: 4,
            label: "Quite a bit=4"
          },
          {
            value: 5,
            label: "Extremely=5"
          }
        ]
      },
      {
        key: "SF-36_T1_26",
        label:
          "ENERGY AND EMOTIONS:\nThese questions are about how you feel and how things have been with you during the last 4 weeks. For each question, please give the answer that comes closest to the way you have been feeling",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_27",
        label: "Did you feel full of pep?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time=1"
          },
          {
            value: 2,
            label: "Most of the time=2"
          },
          {
            value: 3,
            label: "A good bit of the time=3"
          },
          {
            value: 4,
            label: "Some of the time=4"
          },
          {
            value: 5,
            label: "A little bit of the time=5"
          }
        ]
      },
      {
        key: "SF-36_T1_28",
        label: "Have you been a very nervous person?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_29",
        label:
          "Have you felt so down in the dumps that nothing could cheer you up?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_30",
        label: "Have you felt calm and peaceful?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_31",
        label: "Did you have a lot of energy?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_32",
        label: "Have you felt downhearted and blue?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_33",
        label: "Did you feel worn out?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_34",
        label: "Have you been a happy person?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_35",
        label: "Did you feel tired?",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "A good bit of the time"
          },
          {
            value: 4,
            label: "Some of the time"
          },
          {
            value: 5,
            label: "A little bit of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_36",
        label:
          "SOCIAL ACTIVITIES: During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your social activities (like visiting with friends, relatives, etc.)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "All of the time=1"
          },
          {
            value: 2,
            label: "Most of the time"
          },
          {
            value: 3,
            label: "Some of the time"
          },
          {
            value: 4,
            label: "A little bit of the time"
          },
          {
            value: 5,
            label: "None of the time"
          }
        ]
      },
      {
        key: "SF-36_T1_37",
        label:
          "GENERAL HEALTH: How true or false is each of the following statements for you?",
        if: null,
        type: "description"
      },
      {
        key: "SF-36_T1_38",
        label: "I seem to get sick a little easier than other people",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true=1"
          },
          {
            value: 2,
            label: "Mostly True=2"
          },
          {
            value: 3,
            label: "Don't Know=3"
          },
          {
            value: 4,
            label: "Mostly False=4"
          },
          {
            value: 5,
            label: "Definitely False=5"
          }
        ]
      },
      {
        key: "SF-36_T1_39",
        label: "I am as healthy as anybody I know",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_T1_40",
        label: "I expect my health to get worse",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_T1_41",
        label: "My health is excellent",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "Definitely true"
          },
          {
            value: 2,
            label: "Mostly True"
          },
          {
            value: 3,
            label: "Don't Know"
          },
          {
            value: 4,
            label: "Mostly False"
          },
          {
            value: 5,
            label: "Definitely False"
          }
        ]
      },
      {
        key: "SF-36_T1_42",
        label: "PHYSICAL FUNCTIONING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_4",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_5",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_6",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_7",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_8",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_9",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_10",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_11",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_12",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            },
            {
              key: "SF-36_T1_13",
              map: {
                "1": 1,
                "2": 2,
                "3": 3
              }
            }
          ],
          divideBy: 10
        }
      },
      {
        key: "SF-36_T1_43",
        label: "\nROLE LIMITATION DUE TO PHYSICAL HEALTH",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_15",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T1_16",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T1_17",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T1_18",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_T1_44",
        label: "ROLE LIMITATION DUE TO EMOTIONAL PROBLEMS",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_20",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T1_21",
              map: {
                "1": 1,
                "2": 2
              }
            },
            {
              key: "SF-36_T1_22",
              map: {
                "1": 1,
                "2": 2
              }
            }
          ],
          divideBy: 3
        }
      },
      {
        key: "SF-36_T1_45",
        label: "ENERGY/FATIGUE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_27",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_31",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_33",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_35",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 4
        }
      },
      {
        key: "SF-36_T1_46",
        label: "EMOTIONAL WELL-BEING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_28",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_29",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_30",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_32",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_34",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      },
      {
        key: "SF-36_T1_47",
        label: "SOCIAL FUNCTIONING",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_36",
              map: {
                "1": 1,
                "2": 2,
                "3": 4,
                "4": 5,
                "5": 6
              }
            },
            {
              key: "SF-36_T1_23",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_T1_48",
        label: "PAIN",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_24",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_25",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 2
        }
      },
      {
        key: "SF-36_T1_49",
        label: "GENERAL HEALTH",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "SF-36_T1_1",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_38",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_39",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_40",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            },
            {
              key: "SF-36_T1_41",
              map: {
                "1": 1,
                "2": 2,
                "3": 3,
                "4": 4,
                "5": 5
              }
            }
          ],
          divideBy: 5
        }
      }
    ]
  },
  {
    type: "ECI (T1)",
    name: "Elixahuser Comorbidity Index",
    codiceDataCompletamento: "DATA_Elixahuser_T1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATA_Elixahuser_T1",
        label: "Date",
        type: "date"
      },
      {
        key: "Elixahuser_T1_0",
        label:
          "Below you will find a list of physical disorders. For each disorder there is a specific score to be assigned. Please mark with a cross the disorders from which the participant has suffered in the course of his or her life. The total score is calculated from the sum of the individual scores in the column.",
        if: null,
        type: "description"
      },
      {
        key: "Elixahuser_T1_1",
        label: "1) Myocardial infarction ",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_2",
        label: "2) Heart disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_3",
        label: "3) Vascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_4",
        label: "4) Cerebrovascular diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_5",
        label: "5) Dementia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_6",
        label: "6) Chronic lung diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_7",
        label: "7) Connective tissue diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_8",
        label: "8) Ulcer",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_9",
        label: "9) Mild liver diseases",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_10",
        label: "10) Diabetes (without complications)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 1,
            label: "1= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_11",
        label: "11) Diabetes with organ damage",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_12",
        label: "12) Ictus",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_13",
        label: "13) Moderate or severe kidney disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_14",
        label: "14) Second non-metastatic tumour",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_15",
        label: "15) Leukaemia",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_16",
        label: "16) Lymphoma, MM",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 2,
            label: "2= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_17",
        label: "17) Moderate or severe liver disease",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 3,
            label: "3= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_18",
        label: "18) Second metastatic tumour",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_19",
        label: "19) AIDS",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 0,
            label: "0= no"
          },
          {
            value: 6,
            label: "6= sì"
          }
        ]
      },
      {
        key: "Elixahuser_T1_20",
        label: "Age (in years)",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: null
      },
      {
        key: "Elixahuser_T1_21",
        label: "TOTAL RAW SCORE",
        if: null,
        type: "number",
        range: null,
        step: "any",
        auto: {
          sum: [
            {
              key: "Elixahuser_T1_1",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_2",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_3",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_4",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_5",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_6",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_7",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_8",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_9",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_10",
              map: {
                "0": 0,
                "1": 1
              }
            },
            {
              key: "Elixahuser_T1_11",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_12",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_13",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_14",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_15",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_16",
              map: {
                "0": 0,
                "2": 2
              }
            },
            {
              key: "Elixahuser_T1_17",
              map: {
                "0": 0,
                "3": 3
              }
            },
            {
              key: "Elixahuser_T1_18",
              map: {
                "0": 0,
                "6": 6
              }
            },
            {
              key: "Elixahuser_T1_19",
              map: {
                "0": 0,
                "6": 6
              }
            }
          ]
        }
      }
    ]
  },
  {
    type: "EQ5D (T1)",
    name: "EQ5D",
    codiceDataCompletamento: "DATE_EQ5D_T1",
    filtro: [
      {
        key: "USER_INFO_userType",
        comparator: "=",
        value: "Pazienti"
      }
    ],
    domande: [
      {
        key: "DATE_EQ5D_T1",
        label: "Date",
        type: "date"
      },
      {
        key: "EQ5D_T1_0",
        label:
          "Under each heading, please tick the ONE box that best describes your health TODAY",
        if: null,
        type: "description"
      },
      {
        key: "EQ5D_T1_1",
        label: "MOBILITY",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems in walking about"
          },
          {
            value: 2,
            label: "I have slight problems in walking about"
          },
          {
            value: 3,
            label: "I have moderate problems in walking about"
          },
          {
            value: 4,
            label: "I have severe problems in walking about"
          },
          {
            value: 5,
            label: "I am unable to walk about"
          }
        ]
      },
      {
        key: "EQ5D_T1_2",
        label: "SELF-CARE",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems washing or dressing myself"
          },
          {
            value: 2,
            label: "I have slight problems washing or dressing myself"
          },
          {
            value: 3,
            label: "I have moderate problems washing or dressing myself"
          },
          {
            value: 4,
            label: "I have severe problems washing or dressing myself"
          },
          {
            value: 5,
            label: "I am unable to wash or dress myself"
          }
        ]
      },
      {
        key: "EQ5D_T1_3",
        label:
          "USUAL ACTIVITIES (e.g. work, study. Housework. Family or leisure activities)",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no problems doing my usual activities"
          },
          {
            value: 2,
            label: "I have slight problems doing my usual activities"
          },
          {
            value: 3,
            label: "I have moderate problems doing my usual activities"
          },
          {
            value: 4,
            label: "I have severe problems doing my usual activities"
          },
          {
            value: 5,
            label: "I am unable doing my usual activities"
          }
        ]
      },
      {
        key: "EQ5D_T1_4",
        label: "PAIN / DISCOMFORT",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I have no pain or discomfort"
          },
          {
            value: 2,
            label: "I have slight pain or discomfort"
          },
          {
            value: 3,
            label: "I have moderate pain or discomfort"
          },
          {
            value: 4,
            label: "I have severe pain or discomfort"
          },
          {
            value: 5,
            label: "I have extreme pain or discomfort"
          }
        ]
      },
      {
        key: "EQ5D_T1_5",
        label: "ANXIETY / DEPRESSION",
        if: null,
        type: "radio-button",
        values: [
          {
            value: 1,
            label: "I am not anxious or depressed"
          },
          {
            value: 2,
            label: "I am slightly anxious or depressed"
          },
          {
            value: 3,
            label: "I am moderately anxious or depressed"
          },
          {
            value: 4,
            label: "I am severely anxious or depressed"
          },
          {
            value: 5,
            label: "I am extremely anxious or depressed"
          }
        ]
      },
      {
        key: "EQ5D_T1_6",
        label: "YOUR HEALTH TODAY",
        if: null,
        type: "number",
        range: {
          from: 0,
          to: 100
        },
        step: "any",
        auto: null
      }
    ]
  }
];
